import React from "react";
import Tree from "../Tree";
import "./styles.css";

function Sun() {
  return (
    <>
      <div className="sun">
        <div className="sunray ray1"></div>
        <div className="sunray ray2"></div>
        <div className="sunray ray3"></div>
      </div>
    </>
  );
}

function Summer(props) {
  let className = "rightContent";
  if (!props.shouldDisplayText) {
    className = "textHidden";
  }

  return (
    <>
      <div className="graphics" onClick={props.handleTextDisplay}>
        {Sun()}
        {<Tree></Tree>}
      </div>
      <div className={className}>
        <b>Summer</b> - In this season, there is lot of grenery out there under
        the hot sun
      </div>
    </>
  );
}

export default Summer;
