import "./App.css";
import React from "react";
import Intro from "./Intro";
import Section from "./Section";

function App() {
  return (
    <div className="App">
      <Intro></Intro>
      <Section component="spring"></Section>
      <Section component="summer"></Section>
      <Section component="fall"></Section>
      <Section component="winter"></Section>
    </div>
  );
}

export default App;
