import "./styles.css";
function Tree(props) {
  const { leaves = true } = props;
  return (
    <>
      <div className="tree">
        {leaves ? (
          <div className="leaves">
            <div className="leaf one leftLeaf"></div>

            <div className="leaf two rightLeaf"></div>
            <div className="leaf three leftLeaf"></div>
            <div className="leaf four rightLeaf"></div>
            <div className="leaf five leftLeaf"></div>
            <div className="leaf six rightLeaf"></div>
          </div>
        ) : null}
        <div className="treeBase">
          <div className="log">
            <div className="twig twigOne"></div>
            <div className="twig twigTwo"></div>
            <div className="twig twigThree"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tree;
