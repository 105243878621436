import React from "react";
import Tree from "../Tree";
import "./styles.css";

function Spring(props) {
  let className = "leftContent";
  if (!props.shouldDisplayText) {
    className = " textHidden";
  }

  return (
    <>
      <div className={className}>
        <b>Spring</b> - In this season, leaves sprout from the trees
      </div>
      <div className="graphics" onClick={props.handleTextDisplay}>
        {<Tree></Tree>}
      </div>
    </>
  );
}

export default Spring;
