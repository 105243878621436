import React from "react";
import Tree from "../Tree";
import "./styles.css";

function Winter(props) {
  let className = "rightContent";
  if (!props.shouldDisplayText) {
    className = "textHidden";
  }

  return (
    <>
      <div className="graphics" onClick={props.handleTextDisplay}>
        <div className="snowflakes">
          <div className="snowflake flake1"></div>
          <div className="snowflake flake2"></div>
          <div className="snowflake flake3"></div>
          <div className="snowflake flake4"></div>
        </div>
        {<Tree leaves={false}></Tree>}
      </div>
      <div className={className}>
        <b>Winter</b> - In this season, there are no leaves on the trees the hot
        sun
      </div>
    </>
  );
}

export default Winter;
