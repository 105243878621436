import Spring from "./Spring";
import Summer from "./Summer";
import Fall from "./Fall";
import Winter from "./Winter";

const componentMap = {
  spring: Spring,
  summer: Summer,
  fall: Fall,
  winter: Winter,
};

export default componentMap;
