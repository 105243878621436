import React from "react";
import Tree from "../Tree";
import "./styles.css";

function Fall(props) {
  let className = "leftContent";
  if (!props.shouldDisplayText) {
    className = " textHidden";
  }

  return (
    <>
      <div className={className}>
        <b>Fall</b> - In this season, all the trees shed their leaves
      </div>
      <div className="graphics" onClick={props.handleTextDisplay}>
        {<Tree> </Tree>}
      </div>
    </>
  );
}

export default Fall;
