import componentMap from "../components";
import React from "react";
import "./styles.css";

function Section(props) {
  const [shouldDisplayText, setTextDisplay] = React.useState(false);

  function handleTextDisplay() {
    setTextDisplay(!shouldDisplayText);
  }

  const Component = componentMap[props.component];
  return (
    <section className={"section " + props.component}>
      <Component
        shouldDisplayText={shouldDisplayText}
        handleTextDisplay={handleTextDisplay}
      ></Component>
    </section>
  );
}

export default Section;
