import "./styles.css";

function Intro() {
  return (
    <section className="section introSection">
      <div> Understanding the journey of a leaf</div>
      <div className="introLeaf"></div>
    </section>
  );
}

export default Intro;
